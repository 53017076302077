import React, { FC } from 'react';

// markup
const Next: FC<{ pageContext: any }> = ({ pageContext }) => {
  const { next, previous } = pageContext;

  return (
    <div className="grid grid-cols-2 md:w-3/4 m-auto my-5 text-sm md:text-lg font-article ">
      <div className="text-left">
        {previous?.frontmatter.slug ? (
          <a className="next" href={previous.frontmatter.slug}>
            ← {previous.frontmatter.title}
          </a>
        ) : null}
      </div>
      <div className="text-right">
        {next?.frontmatter.slug ? (
          <a className="next" href={next.frontmatter.slug}>
            {next.frontmatter.title} →
          </a>
        ) : null}
      </div>
    </div>
  );
};

export default Next;
