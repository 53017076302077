import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import App from '../components/App';
import Next from '../components/Next';
import Author from '../components/Author';

const blogTemplate: FC<{ data: any; pageContext: any }> = ({
  data,
  pageContext,
}) => {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html, timeToRead } = markdownRemark;
  const image = getImage(frontmatter.featuredImage);

  return (
    <App>
      <hr />
      <div className="flex-1 mx-auto  font-article items-center w-full">
        <div className="grid rows-3">
          {image ? (
            <GatsbyImage
              alt={frontmatter.imageCreditName}
              className="gatsbyImage"
              image={image}
            />
          ) : null}
          {frontmatter.isUnsplash && (
            <div className="text-center text-xs">
              Photo by{' '}
              <a
                className="underline"
                href={frontmatter.imageCreditUrl}
                rel="noreferrer"
                target="_blank"
              >
                {frontmatter.imageCreditName}
              </a>{' '}
              on{' '}
              <a
                className="underline"
                href="https://unsplash.com/"
                rel="noreferrer"
                target="_blank"
              >
                Unsplash
              </a>
            </div>
          )}
          <div className=" grid grid-cols-2 w-3/4  text-xs md:text-base pt-6 m-auto lowercase">
            <div className=" text-left">
              <a
                className="hover:underline font-tags"
                href={`/category/${frontmatter.category}/`}
              >
                {frontmatter.category}
              </a>{' '}
              <span>•</span>{' '}
              <a
                className="hover:underline font-tags lowercase "
                href={`/tags/${frontmatter.tag}`}
              >
                {frontmatter.tag}
              </a>
            </div>
            <div className="text-right ">{frontmatter.date}</div>
          </div>
          <div className=" text-center font-bold text-3xl md:text-4xl pb-2 mt-4">
            {frontmatter.title}
          </div>
          <div className=" text-center">{timeToRead}min read</div>
        </div>
        <div className="row-start-2 p-1 md:p-3">
          <div
            className="blog-post-content text-left m-auto w-11/12 md:w-3/5 text-lg"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
        <Author />
        <Next pageContext={pageContext} />
      </div>
    </App>
  );
};
export default blogTemplate;

export const pageQuery = graphql`
  query ($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      id
      frontmatter {
        date(formatString: "MMMM DD, YYYY", locale: "en")
        slug
        title
        category
        imageCreditUrl
        imageCreditName
        tag
        isUnsplash
        featuredImage {
          childImageSharp {
            gatsbyImageData(
              height: 500
              placeholder: BLURRED
              width: 1600
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
      timeToRead
    }
  }
`;
