import React, { FC } from 'react';
import img from '../../assets/blog-image.jpg';

const Author: FC = () => (
  <>
    <hr className="pb-10 mt-10 " />
    <div className="flex justify-center m-auto font-category ">
      <div className="md:flex p- items-center">
        <div className="pr-16">
          <img
            alt="About"
            className="rounded-full m-auto"
            src={img}
            width={150}
          />
        </div>
        <div>
          <p className="text-2xl font-bold uppercase font-category">
            About the author
          </p>
          <p className="text-lg font-semibold">Ludivine Achouri</p>

          <p>Passionate web developer from France</p>
        </div>
      </div>
    </div>
    <hr className="mt-10 pb-10" />
  </>
);

export default Author;
